@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



li{
  list-style-type: circle;
}

.logo{
  color: rgba(255, 255, 255, 0.9);
}
.forBackground{
  background-color: rgba(255, 255, 255, 0.9);
}
.head{
  color: rgba(255, 255, 255, 0.9);

}
.head:hover{
  border-color: rgba(255, 255, 255, 0.9);;
}

.sinFrans{
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.forHeight{
  height: 400px;
}